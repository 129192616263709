import { getExperimentVariation } from "../helpers/ab";
import {
  initialize,
  fireImpression as abReaderFireImpression,
} from "@vp/ab-reader";
import reader from "./ctc-reader";
import { Variations } from "../constants";

let ExperimentKey = "cookie_consent_v2_de_fr";

const Experiments = [
  {
    key: "cookie_consent_v4_uk_de",
    treatment: "consent_manager_v4_uk_de_test",
  },
  {
    key: "cookie_consent_v5_fr",
    treatment: "consent_manager_v5_fr_test",
  },
];

export const initializeExperiment = () => {
  try {
    initialize();
  } catch (e) {
    console.error(
      `Initialization of the experimentation library failed. Details: ${e.message}`,
    );
  }
};

// all locales: if treatment is enabled and experiment is started, show respective variation from the experiment
// all locales except fr: if treatment is enabled and experiment is not started, show variation2
// fr locale: if treatment is enabled and experiment is not started, show ControlFr variation
export const getExperimentInfo = async ({ defaultVariation, resources }) => {
  defaultVariation = validateVariation(
    defaultVariation ?? resources?.variation,
  );

  if (defaultVariation) {
    return {
      isExperimentEnabled: false,
      variation: defaultVariation,
    };
  }

  for (let experiment of Experiments) {
    ExperimentKey = experiment.key;
    let isTreatmentEnabled = reader.isTreatmentEnabled(experiment.treatment);
    if (isTreatmentEnabled) {
      let variation = await getVariation();
      let isExperimentEnabled = !!variation;
      if (isExperimentEnabled) {
        return {
          isExperimentEnabled: true,
          variation,
        };
      } else if (experiment.key === "cookie_consent_v5_fr") {
        return {
          isExperimentEnabled: false,
          variation: Variations.ControlFr,
        };
      } else if (experiment.key === "cookie_consent_v4_uk_de") {
        return {
          isExperimentEnabled: false,
          variation: Variations.Variation2,
        };
      }
    }
  }

  //if none of the treatments and experiment pairs are enabled
  return {
    variation: Variations.Variation2,
    isExperimentEnabled: false,
  };
};

export const fireImpression = (variation) => {
  try {
    abReaderFireImpression(ExperimentKey, variation);
  } catch (e) {
    console.error(
      `Firing of an Impression of the experiment '${ExperimentKey}' failed. Details: ${e.message}`,
    );
  }
};

const getVariation = async () => {
  try {
    return await getExperimentVariation(ExperimentKey);
  } catch (e) {
    console.error(
      `Fetching the experiment '${ExperimentKey}' value is failed. Details: ${e.message}`,
    );
  }
};

const validateVariation = (rawVariation) => {
  const availableVariations = [
    Variations.Variation2,
    Variations.Variation3,
    Variations.Variation4,
    Variations.Variation5,
    Variations.ControlFr,
    Variations.Variation1Fr,
    Variations.Variation2Fr,
  ];

  if (availableVariations.includes(rawVariation)) {
    return rawVariation;
  }

  return undefined;
};
