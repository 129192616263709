import { activate, initialize, whenAvailable } from "@vp/ab-reader";
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface IABTestContext {
  currentExperiments: Record<string, string>;
}

export const ABTestContext = createContext<IABTestContext>({
  currentExperiments: {},
});

export type Experiment = {
  key: string;
  variations: Record<string, string>;
  precheck?: string;
  localOverride?: string; // for local testing only
};

export const EXPERIMENTS: Record<string, Experiment> = {
  // EXAMPLE: {
  //   key: "example_key",
  //   variations: {
  //     TEST: "test"
  //   },
  //   precheck: "examplePrecheck", matches key of testPrechecks
  //   localOverride: "test"
  // }
};

const activateExperiment = (experiment: Experiment): string => {
  if (experiment.localOverride) {
    return experiment.localOverride;
  }
  return activate(experiment.key) || "";
};

const ABTestContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentExperiments, setCurrentExperiments] = useState<
    Record<string, string>
  >({});

  // Example using state variables in precheck
  // const { data: BffData } = useBffData();

  const experimentPrechecks: Record<string, () => Promise<boolean>> = {
    // examplePrecheck: async () => {
    //   if (BffData?.productKey) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  };

  // use isDepsLoaded to prevent multiple calls to initialize
  // return true only if state variables used in prechecks are loaded
  const isDepsLoaded = useMemo(() => {
    // let allDepsLoaded = false;
    // if (BffData) allDepsLoaded = true;
    // return allDepsLoaded;
    return true;
  }, []); // },[BffData]);

  useEffect(() => {
    const setUpExperiments = async () => {
      initialize();
      whenAvailable(async (isAvailable) => {
        if (isAvailable) {
          const experiments: any = {};
          await Promise.allSettled(
            Object.values(EXPERIMENTS).map(async (experiment: Experiment) => {
              let precheckPassed = true;
              if (experiment.precheck) {
                precheckPassed =
                  await experimentPrechecks[experiment.precheck]();
              }
              experiments[experiment.key] = precheckPassed
                ? activateExperiment(experiment)
                : "";
            }),
          );
          setCurrentExperiments(experiments);
        }
      }, 2000);
    };
    if (isDepsLoaded) {
      setUpExperiments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDepsLoaded]);

  const contextValue: IABTestContext = useMemo(() => {
    return {
      currentExperiments,
    };
  }, [currentExperiments]);

  return (
    <ABTestContext.Provider value={contextValue}>
      {children}
    </ABTestContext.Provider>
  );
};

export default ABTestContextProvider;
