import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Typography,
  GridContainer,
  Row,
  Column,
  Link,
  H2,
} from "@vp/swan";

import l10n from "../localization/localization";
import styles from "../styles/cookie-message-style.scss";
import getUrl from "./get-url";
import { Variations } from "../constants";

export default function BannerVariationV4_5(props) {
  const { onAcceptAll, onAcceptNecessary, onChangePreferences, variation } =
    props;
  return (
    <GridContainer
      className={`${styles.cookiePolicyVariation4} grid-container-proportional consent-manager-banner`}
      backgroundColor="standard"
    >
      <Row marginTop="3" marginBottom="5">
        <Column span={12} spanXs={12}>
          <H2 fontSkin="title-subsection" className="swan-font-skin-title-item">
            {l10n.t("bannerV5.titleText")}
          </H2>
        </Column>
      </Row>
      <Row marginY="4">
        <Column span={12}>
          <Typography textAlign="left" fontSize="small">
            {getBannerContentPart(0)}
            <Link href={getPolicyLink()}>
              {l10n.t("bannerV5.policyLinkText")}
            </Link>
            {getBannerContentPart(1)}
          </Typography>
        </Column>
      </Row>
      <Row marginTop="4">
        {getButtons(
          variation,
          onChangePreferences,
          onAcceptAll,
          onAcceptNecessary,
        )}
      </Row>
    </GridContainer>
  );
}

function getPolicyLink() {
  return getUrl(l10n.tUrl("privacyAndCookiePolicy"));
}

function getBannerContentPart(part) {
  const policyText = l10n.t("bannerV5.content").split("#policy-link#");
  if (policyText.length > part) {
    return policyText[part];
  }

  return "";
}

function getButtons(
  variation,
  onChangePreferences,
  onAcceptAll,
  onAcceptNecessary,
) {
  let skinValue = "secondary";

  if (variation === Variations.Variation5) {
    skinValue = "primary";
  }

  return (
    <>
      <Column span={4}>
        <Button
          width="full-width"
          skin={skinValue}
          size="mini"
          title={l10n.t("bannerV5.rejectAllText")}
          aria-label={l10n.t("bannerV5.rejectAllText")}
          onClick={onAcceptNecessary}
          className={`${styles.wrappedText} ${styles.alignedButton}`}
        >
          {[l10n.t("bannerV5.rejectAllText")]}
        </Button>
      </Column>
      <Column span={4}>
        <Button
          width="full-width"
          skin={skinValue}
          size="mini"
          className={`${styles.cookiePolicyPreferencesButton} ${styles.wrappedText} ${styles.alignedButton}`}
          title={l10n.t("bannerV5.settings")}
          aria-label={l10n.t("bannerV5.settings")}
          onClick={onChangePreferences}
        >
          {l10n.t("bannerV5.settings")}
        </Button>
      </Column>
      <Column span={4}>
        <Button
          skin="primary"
          width="full-width"
          size="mini"
          title={l10n.t("bannerV5.accept")}
          aria-label={l10n.t("bannerV5.accept")}
          onClick={onAcceptAll}
          className={`${styles.wrappedText} ${styles.alignedButton}`}
        >
          {l10n.t("bannerV5.accept")}
        </Button>
      </Column>
    </>
  );
}

BannerVariationV4_5.displayName = "Banner";

BannerVariationV4_5.propTypes = {
  onAcceptAll: PropTypes.func.isRequired,
  onAcceptNecessary: PropTypes.func.isRequired,
  onChangePreferences: PropTypes.func.isRequired,
  variation: PropTypes.string.isRequired,
};
