import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Typography,
  GridContainer,
  Row,
  Column,
  Link,
  H2,
} from "@vp/swan";

import l10n from "../localization/localization";
import styles from "../styles/cookie-message-style.scss";
import getUrl from "./get-url";
import MultilineText from "./multiline-text";
import log from "eslint-plugin-react/lib/util/log";
import { Variations } from "../constants";

export default function BannerFr(props) {
  const { onAcceptAll, onAcceptNecessary, onChangePreferences, variation } =
    props;
  return (
    <GridContainer
      className={`${styles.cookiePolicyControl} grid-container-proportional consent-manager-banner`}
      backgroundColor="standard"
    >
      <Row marginTop="3" marginBottom="5">
        <Column
          span={12}
          spanXs={12}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Link
            textAlign="right"
            title={l10n.t("bannerExperimentV5.cta.accept")}
            aria-label={l10n.t("bannerExperimentV5.cta.accept")}
            fontSize="small"
            render={(p) => (
              <Button
                skin="link"
                className={p.className}
                onClick={onAcceptNecessary}
              >
                {l10n.t("bannerExperimentV5.cta.reject")}
              </Button>
            )}
          >
            <MultilineText
              mobileText={l10n.t(
                "bannerV5.continueWithoutAccepting.mobileText",
                {
                  returnObjects: true,
                },
              )}
              text={l10n.t("bannerV5.continueWithoutAccepting.desktopText", {
                returnObjects: true,
              })}
            />
          </Link>
        </Column>
      </Row>
      <Row marginY="4">
        <Column span={12} spanXs={12}>
          <H2 fontSkin="title-subsection">{getTitle(variation)}</H2>
        </Column>
      </Row>
      <Row marginY="4">
        <Column span={12}>
          <Typography textAlign="left" fontSize="small">
            {getBannerContentPart(0, variation)}
            <Link href={getPolicyLink()}>
              {l10n.t("bannerV5.policyLinkText")}
            </Link>
            {getBannerContentPart(1, variation)}
          </Typography>
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <Button
            width="full-width"
            skin="secondary"
            size="mini"
            className={`${styles.textButton} ${styles.wrappedText}`}
            title={l10n.t("bannerV5.settings")}
            aria-label={l10n.t("bannerV5.settings")}
            onClick={onChangePreferences}
          >
            {l10n.t("bannerV5.settings")}
          </Button>
        </Column>
        <Column span={6}>
          <Button
            skin="primary"
            width="full-width"
            size="mini"
            className={`${styles.textButton} ${styles.wrappedText}`}
            title={l10n.t("bannerExperimentV5.cta.accept")}
            aria-label={l10n.t("bannerExperimentV5.cta.accept")}
            onClick={onAcceptAll}
          >
            {l10n.t("bannerExperimentV5.cta.accept")}
          </Button>
        </Column>
      </Row>
    </GridContainer>
  );
}

function getPolicyLink() {
  return getUrl(l10n.tUrl("privacyAndCookiePolicy"));
}

function getTitle(variation) {
  if (
    variation === Variations.Variation1Fr ||
    variation === Variations.Variation2Fr
  ) {
    return l10n.t("bannerExperimentV5.title");
  }
  return l10n.t("bannerV5.titleText");
}

function getBannerContentPart(part, variation) {
  if (variation === Variations.Variation1Fr) {
    return getVariation1BannerContentPart(part);
  }

  if (variation === Variations.Variation2Fr) {
    return getVariation2BannerContentPart(part);
  }

  return getControlBannerContentPart(part);
}

function getControlBannerContentPart(part) {
  const policyText = l10n.t("bannerV5.content").split("#policy-link#");
  return policyText.length > part ? policyText[part] : "";
}

function getVariation1BannerContentPart(part) {
  const policyText = l10n
    .t("bannerExperimentV5.variation1.content")
    .split("#policy-link#");
  return getTextPart(policyText, part);
}

function getVariation2BannerContentPart(part) {
  const policyTextObject = l10n.t("bannerExperimentV5.variation2.content", {
    returnObjects: true,
  });
  const text = policyTextObject[0] + "\n" + policyTextObject[1];
  const policyText = text.split("#policy-link#");
  return getTextPart(policyText, part);
}

function getTextPart(policyText, part) {
  if (policyText.length > part) {
    const textParts = policyText[part].split("\n");
    return (
      <span>
        {textParts.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index < textParts.length - 1 && index !== 1 && <br />}
          </React.Fragment>
        ))}
      </span>
    );
  }
  return null;
}

BannerFr.displayName = "Banner";

BannerFr.propTypes = {
  onAcceptAll: PropTypes.func.isRequired,
  onAcceptNecessary: PropTypes.func.isRequired,
  onChangePreferences: PropTypes.func.isRequired,
};
