import EventEmitter from "events";
import { PreferenceEmbedded, PreferenceDialog } from "./preferences";
import {
  DataSalePreferenceEmbedded,
  DataSalePreferenceDialog,
} from "./preferences/data-sale";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { trackManageSettings } from "../consent-manager-builder/tracking";
import { Mode, Variations } from "../constants";
import BannerVariationV2 from "./banner-variation-2";
import BannerVariationV3 from "./banner-variation-3";
import BannerVariationV4_5 from "./banner-variation-4-5";
import BannerFr from "./banner-fr";

const emitter = new EventEmitter();

const preferenceDialogId = "consentManagerPreferenceDialog";
const AcceptAllBannerLabel = "Accept All cookies:Banner";
const AcceptOnlyNecessaryCookiesLabel = "Accept Only Necessary cookies:Banner";
const AcceptAllDialogLabel = "Accept All cookies:Pop-Up";
const ConfirmSelectionLabel = "Confirm selection";

export function openDialog() {
  emitter.emit("openDialog");
}

export default class Container extends PureComponent {
  static displayName = "Container";

  static propTypes = {
    container: PropTypes.string.isRequired,
    setPreferences: PropTypes.func.isRequired,
    resetPreferences: PropTypes.func.isRequired,
    saveConsent: PropTypes.func.isRequired,
    preferences: PropTypes.object.isRequired,
    variation: PropTypes.string,
    showBanner: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
    ccpa: PropTypes.bool.isRequired,
    saveDataSale: PropTypes.func.isRequired,
    onAcceptAll: PropTypes.func,
    onSave: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      preferenceDialogIsOpen: false,
    };
  }

  render() {
    const { preferences, variation, showBanner, mode, ccpa } = this.props;

    if (mode === Mode.Dialog) {
      return (
        <div>
          {showBanner && this.getBanner(variation)}
          {(showBanner || this.state.preferenceDialogIsOpen) &&
            this.getPreferenceDialog(variation, preferences, ccpa)}
        </div>
      );
    }

    if (mode === Mode.Embedded) {
      return <>{this.getPreferenceEmbedded(variation, preferences, ccpa)}</>;
    }

    throw new Error(`Not supported mode '${mode}'`);
  }

  componentDidMount() {
    emitter.on("openDialog", this.openDialog);
  }

  componentWillUnmount() {
    emitter.removeListener("openDialog", this.openDialog);
  }

  openDialog = () => {
    this.setState({
      preferenceDialogIsOpen: true,
    });
    trackManageSettings();
  };

  closeDialog = () => {
    this.setState({
      preferenceDialogIsOpen: false,
    });
  };

  handleBannerAccept = () => {
    this.handleAcceptAll(AcceptAllBannerLabel);
  };

  handleBannerAcceptOnlyNeccessary = () => {
    const label = AcceptOnlyNecessaryCookiesLabel;
    const { saveConsent, setPreferences, onSave } = this.props;
    setPreferences({
      functional: false,
      advertising: false,
      sessionReplay: false,
    });
    saveConsent({ label, acceptAll: false });
    onSave && onSave();
  };

  handleAcceptAll = (label) => {
    const { saveConsent, setPreferences, onAcceptAll } = this.props;
    setPreferences({
      functional: true,
      advertising: true,
      sessionReplay: true,
    });
    this.closeDialog();
    saveConsent({ label, acceptAll: true });
    onAcceptAll && onAcceptAll();
  };

  handleCategoryChange = (category, value) => {
    const { setPreferences } = this.props;

    setPreferences({
      [category]: value,
    });
  };

  handleSave = () => {
    const { saveConsent, onSave } = this.props;
    this.closeDialog();
    saveConsent({ label: ConfirmSelectionLabel, acceptAll: false });
    onSave && onSave();
  };

  handleCancel = () => {
    const { resetPreferences } = this.props;

    resetPreferences();

    this.setState({
      preferenceDialogIsOpen: false,
    });
  };

  handleDataSaleCategoryChange(value) {
    const { saveDataSale } = this.props;
    saveDataSale(value);
  }

  getBanner = (variation) => {
    if (variation === Variations.Variation2) {
      return (
        <BannerVariationV2
          onAcceptAll={this.handleBannerAccept}
          onAcceptNecessary={this.handleBannerAcceptOnlyNeccessary}
          onChangePreferences={this.openDialog}
        />
      );
    }

    if (variation === Variations.Variation3) {
      return (
        <BannerVariationV3
          onAcceptAll={this.handleBannerAccept}
          onAcceptNecessary={this.handleBannerAcceptOnlyNeccessary}
          onChangePreferences={this.openDialog}
          variation={Variations.Variation3}
        />
      );
    }

    if (
      variation === Variations.Variation4 ||
      variation === Variations.Variation5
    ) {
      return (
        <BannerVariationV4_5
          onAcceptAll={this.handleBannerAccept}
          onAcceptNecessary={this.handleBannerAcceptOnlyNeccessary}
          onChangePreferences={this.openDialog}
          variation={variation}
        />
      );
    }

    if (
      variation === Variations.ControlFr ||
      variation === Variations.Variation1Fr ||
      variation === Variations.Variation2Fr
    ) {
      return (
        <BannerFr
          onAcceptNecessary={this.handleBannerAcceptOnlyNeccessary}
          onChangePreferences={this.openDialog}
          variation={variation}
          onAcceptAll={this.handleBannerAccept}
        />
      );
    }

    return (
      <BannerVariationV2
        onAcceptAll={this.handleBannerAccept}
        onAcceptNecessary={this.handleBannerAcceptOnlyNeccessary}
        onChangePreferences={this.openDialog}
      />
    );
  };

  getPreferenceDialog = (variation, preferences, ccpa) => {
    if (ccpa === true) {
      return (
        <DataSalePreferenceDialog
          id={preferenceDialogId}
          dataSale={preferences.dataSale}
          isOpen={this.state.preferenceDialogIsOpen}
          onCancel={this.closeDialog}
          onChange={(_, value) => this.handleDataSaleCategoryChange(value)}
        />
      );
    }

    return (
      <PreferenceDialog
        id={preferenceDialogId}
        advertising={preferences.advertising}
        functional={preferences.functional}
        sessionReplay={preferences.sessionReplay}
        isOpen={this.state.preferenceDialogIsOpen}
        onCancel={this.handleCancel}
        onSave={this.handleSave}
        onChange={this.handleCategoryChange}
        onAcceptAll={() => this.handleAcceptAll(AcceptAllDialogLabel)}
      />
    );
  };

  getPreferenceEmbedded = (variation, preferences, ccpa) => {
    if (ccpa) {
      return (
        <DataSalePreferenceEmbedded
          preferences={preferences}
          handleCategoryChange={(_, value) =>
            this.handleDataSaleCategoryChange(value)
          }
        />
      );
    }

    return (
      <PreferenceEmbedded
        preferences={preferences}
        handleCategoryChange={this.handleCategoryChange}
        handleAcceptAll={() => this.handleAcceptAll(AcceptAllDialogLabel)}
        handleSave={this.handleSave}
        isPrimarySkin={variation !== Variations.Variation2}
      />
    );
  };
}
