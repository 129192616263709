import { useContext } from "react";
import { GroupsAndTeamsContext } from "../components/groupsAndTeams/context/GroupsAndTeamsContext";
import { useScreenClass } from "@vp/swan";

export const useShowZoomableImage = () => {
  const { isYsdDesign } = useContext(GroupsAndTeamsContext);

  // YSD designs don't have high-resolution previews for zoom, leave them disabled
  // xs = mobile screen, sm = tablet screen (both have pinch to zoom and don't need this feature)
  const screenSize = useScreenClass();
  const isMobileOrTabletView = screenSize === "xs" || screenSize === "sm";

  return !isYsdDesign && !isMobileOrTabletView;
};
